<template>
    <div class="dynamic-fields">
        <div class="mt-10"></div>
        <div
            class="dynamic-field"
            :class="{
                disabled: field.is_disabled,
                'dynamic-field_dropdown':
                    fields.length > 5 &&
                    (field.type === 'timezone' ||
                        field.type === 'currency' ||
                        field.type === 'dropdown' ||
                        field.type === 'country')
            }"
            v-for="(field, index) in fields.filter(f => !f.is_hidden)"
            :key="index"
        >
            <errors-container
                v-if="field.type === 'checkbox'"
                :color="field.validation && field.validation.color"
                :errors="errors[field.name]"
            >
                <flex-container
                    align="flex-start"
                    :errors="errors[field.name]"
                    :required="field.is_required"
                    class="checkbox-container"
                >
                    <DefaultCheck class="mr-10 mt-1" @update="field.value = $event" :checked="!!field.value" />
                    <p class="cursor-pointer" @click="field.value = !field.value">
                        <span v-html="field.title" class="text-view"></span>
                    </p>
                </flex-container>
            </errors-container>

            <DefaultInput
                v-model="field.value"
                v-else-if="field.type === 'email'"
                :errors="errors[field.name]"
                :required="field.is_required"
                :format="field.format"
                :validation="field.validation"
                :label="$t('enter_email')"
                autocomplete="email"
                type="email"
                name="email"
            />
            <DefaultInput
                v-model="field.value"
                v-if="field.type === 'text'"
                :name="field.name"
                :format="field.format"
                :validation="field.validation"
                :errors="errors[field.name]"
                :autocomplete="field.is_disabled ? 'off' : 'on'"
                :type="field.type"
                :required="field.is_required"
                :label="field.title"
                :placeholder="field.input_title || field.text"
            />
            <DefaultInput
                v-model="field.value"
                v-if="
                    field.type === 'name' ||
                    field.type === 'first_name' ||
                    field.type === 'full_name' ||
                    field.type === 'last_name' ||
                    field.type === 'skip' ||
                    field.type === 'city' ||
                    field.type === 'textarea' ||
                    field.type === 'code'
                "
                :format="field.format"
                :validation="field.validation"
                :is-area="field.type === 'textarea'"
                :name="field.name"
                :errors="errors[field.name]"
                :required="field.is_required"
                :autocomplete="field.type === 'name' ? '' : 'on'"
                :label="field.input_title || field.text"
            />
            <DefaultPhoneInput
                v-if="field.type === 'phone'"
                v-model="field.value"
                :format="field.format"
                :validation="field.validation"
                :name="field.name"
                :errors="errors[field.name]"
                :required="field.is_required"
                :label="field.input_title || field.text"
            />

            <DefaultPasswordInput
                v-if="field.type === 'password' || field.type === 'password_confirmation'"
                v-model="field.value"
                :name="field.name"
                :format="field.format"
                :validation="field.validation"
                autocomplete="new-password"
                :errors="errors[field.name]"
                :required="field.is_required"
                :label="field.input_title || field.text"
            />
            <errors-container
                v-if="field.type === 'date'"
                :color="field.validation && field.validation.color"
                :errors="errors[field.name]"
            >
                <DefaultDateDropdown
                    :format="field.format"
                    :validation="field.validation"
                    :border-color="errors[field.name] ? field.validation.color : '#E0E0EC'"
                    flexible-label
                    style-modifier="bottom"
                    @update="field.value = $event"
                    :loading="field.loading"
                    :cases="field.values"
                    :active-case="field.value || ''"
                    :label="field.input_title || field.text"
                    :required="field.is_required"
                />
            </errors-container>
            <!--|| field.type === 'tag'-->
            <errors-container
                v-if="
                    field.type === 'timezone' ||
                    field.type === 'currency' ||
                    field.type === 'dropdown' ||
                    field.type === 'country'
                "
                :color="field.validation && field.validation.color"
                :errors="errors[field.name]"
            >
                <DefaultCountriesDropdown
                    :format="field.format"
                    :validation="field.validation"
                    :border-color="errors[field.name] ? field.validation.color : '#E0E0EC'"
                    flexible-label
                    @update="field.value = $event"
                    :loading="field.loading"
                    :cases="field.values"
                    :country="field.value || ''"
                    :label="field.input_title || field.text"
                    :required="field.is_required"
                    v-if="field.type === 'country'"
                />
                <DefaultDropdown
                    v-else
                    :format="field.format"
                    :validation="field.validation"
                    :border-color="errors[field.name] ? field.validation.color : '#E0E0EC'"
                    flexible-label
                    @update="field.value = $event"
                    :loading="field.loading"
                    :active-case="field.value || ''"
                    :label="field.input_title || field.text"
                    :required="field.is_required"
                    :cases="field.values"
                />
            </errors-container>
        </div>
    </div>
</template>

<script>
import ErrorsContainer from "@components/Containers/ErrorsContainer.vue"
import FlexContainer from "@components/Containers/FlexContainer.vue"
import DefaultCountriesDropdown from "@components/Forms/country/DefaultCountriesDropdown.vue"
import DefaultCheck from "@components/Forms/DefaultCheck.vue"
import DefaultDateDropdown from "@components/Forms/DefaultDateDropdown.vue"
import DefaultInput from "@components/Forms/DefaultInput"
import DefaultPhoneInput from "@components/Forms/phone/DefaultPhoneInput"
import DefaultPasswordInput from "@components/Forms/DefaultPasswordInput"
import DefaultDropdown from "@components/Forms/DefaultDropdown"
import axios from "~axios"

export default {
    components: {
        DefaultCheck,
        FlexContainer,
        DefaultDateDropdown,
        DefaultCountriesDropdown,
        ErrorsContainer,
        DefaultDropdown,
        DefaultPasswordInput,
        DefaultPhoneInput,
        DefaultInput
    },
    props: {
        errors: {
            type: Object,
            default: () => {}
        },
        fields: {
            type: Array,
            default: () => []
        }
    },
    name: "AuthorizationDynamicFields",
    created() {
        const toPreloadFieldsMap = {
            timezone: this.getTimezones,
            currency: this.getCurrencies,
            country: this.getCountries
        }
        setTimeout(async () => {
            for (let field of this.fields) {
                if (toPreloadFieldsMap[field.type]) {
                    field.laoding = true
                    field.values = await toPreloadFieldsMap[field.type]()

                    if (field.type === "country" && field.format && field.format.default_country) {
                        let defaultCountry = null

                        if (field.format.default_country.iso2 === "auto") {
                            defaultCountry = field.values.find(
                                c => c.iso_2 && c.iso_2.toLowerCase() === window.country.toLowerCase()
                            )
                        } else {
                            defaultCountry = field.values.find(
                                c => c.iso_2 && c.iso_2.toLowerCase() === field.format.default_country.iso2
                            )
                        }

                        if (defaultCountry) {
                            field.value = defaultCountry
                        }
                    }

                    field.laoding = false
                }
            }
        })
        for (const field of this.fields) {
            if (field.type === "dropdown") {
                for (const value of field.values) {
                    value.name = value.value
                }
            }

            if (field.type === "date" && field.format && field.format.default_date) {
                if (field.format.default_date === "now") {
                    field.value = new Date()
                } else {
                    field.value = new Date(field.format.default_date)
                }
            }

            if (field.type === "text" || field.type === "textarea" || field.type === "phone") {
                if (field.default_value) {
                    field.value = field.default_value
                }
            }

            if (field.type === "dropdown") {
                if (field.default_value) {
                    field.value = field.values.find(f => f.value == field.default_value)
                }
            }
        }
    },
    methods: {
        async getCurrencies() {
            const result = await axios.get("/currencies")
            if (result) {
                return result.data.data.map(c => {
                    return {
                        ...c,
                        name: `${c.code} ${c.code ? "- " : ""}${c.html_code}`
                    }
                })
            }
            return []
        },
        async getTimezones() {
            const result = await axios.get("/timezones")
            if (result) {
                return result.data.data.map(t => {
                    let utc = t.value.replace(/UTC/g, "")
                    t.originalName = t.name
                    t.name = `${utc} ${t.name}`
                    return t
                })
            }
            return []
        },
        async getCountries() {
            const result = await axios.get("/countries")
            if (result) {
                return result.data.data
            }
            return []
        }
    }
}
</script>

<style scoped lang="sass">
.checkbox-container
    .text-view
        font-size: 13px
        &::v-deep
            p
                display: inline-block
.dynamic-field
    &:not(&:last-child)
        margin-bottom: 17px
    &:last-child
       &.dynamic-field_dropdown
           margin-bottom: 50px
.dynamic-fields
    &::v-deep
        .simplebar-vertical
            width: 4px !important
</style>
