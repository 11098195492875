<template>
    <button @click="$emit('click')" :class="{ disabled }" class="button-next">
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    name: "PublicModalButtonNext"
}
</script>

<style scoped lang="sass">
.button-next
    background: #3965FF
    width: 100%
    border-radius: 7px
    font-family: 'Ubuntu', serif
    font-size: 15px
    line-height: 22px
    font-weight: 500
    height: 45px
    color: #fff
    display: flex
    align-items: center
    justify-content: center
    transition: background .2s
    &:hover
        background: #1C47DF
</style>
