<template>
    <div class="default-password-input">
        <DefaultInput
            @input="$emit('input', $event)"
            :type="isHidden ? 'password' : 'text'"
            :name="name"
            :placeholder="placeholder"
            :errors="errors"
            :value="value"
            :required="required"
            :use-focus="useFocus"
            :autocomplete="autocomplete"
            :label="label"
        />
        <button
            @keydown.enter.prevent
            tabindex="-1"
            type="button"
            @click="isHidden = !isHidden"
            class="default-password-input__handler"
        >
            <EyeStrokedIcon width="20" height="20" v-if="isHidden" />
            <EyeIcon fill="#3965FF" width="20" height="20" v-else />
        </button>
    </div>
</template>

<script>
import DefaultInput from "@components/Forms/DefaultInput"
import EyeIcon from "@icons/EyeIcon"
import EyeStrokedIcon from "@icons/EyeStrokedIcon"

export default {
    props: {
        value: {
            type: [String, Number],
            default: ""
        },
        label: {
            type: String,
            default: ""
        },
        useFocus: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: "password"
        },
        autocomplete: {
            type: String,
            default: "password"
        },
        placeholder: {
            type: String,
            default: ""
        },
        errors: {
            type: Array,
            default: () => []
        },
        required: {
            type: Boolean,
            default: false
        }
    },
    model: {
        prop: "value",
        event: "input"
    },
    name: "DefaultPasswordInput",
    components: { EyeStrokedIcon, EyeIcon, DefaultInput },
    data() {
        return {
            isHidden: true
        }
    }
}
</script>

<style scoped lang="sass">
.default-password-input
    position: relative
    &__handler
        position: absolute
        top: 0
        height: 45px
        padding: 13px
        right: 0
    &::v-deep
        .default-input__input
            padding-right: 40px
</style>
