<template>
    <button
        type="button"
        @keydown.prevent.stop
        tabindex="-1"
        @click="$emit('click')"
        :class="{ disabled }"
        class="button-prev"
    >
        {{ text }}
    </button>
</template>

<script>
export default {
    props: {
        text: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    name: "PublicModalButtonPrev"
}
</script>

<style scoped lang="sass">
.button-prev
    background: #fff
    width: 100%
    border-radius: 7px
    font-family: 'Ubuntu', serif
    font-size: 15px
    line-height: 22px
    font-weight: 500
    height: 45px
    color: #151515
    display: flex
    align-items: center
    justify-content: center
    transition: .2s
    text-underline-offset: 4px
    &:hover
        text-decoration: underline
</style>
