<template>
    <h3 class="public-modal-title">
        <slot></slot>
    </h3>
</template>

<script>
export default {
    name: "PublicModalTitle"
}
</script>

<style scoped lang="sass">
.public-modal-title
    color: #151515
    font-size: 20px
    line-height: 26px
    text-align: center
    font-family: 'Ubuntu', serif
    font-weight: 500
    @media (max-width: 480px)
        font-size: 16px
        line-height: 21px
</style>
